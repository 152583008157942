@import "../../../Variables.module.scss";

[class*="themeselector"] {
    .themetoggle {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
            font-size: 20px;
            float: left;
            margin-right: 6px;
        }
        span:nth-of-type(2) {
            margin-left: 6px;
        }
        .toggle {
            width: 40px;
            height: 20px;
            border-radius: 10px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            .toggle__circle {
                width: 16px;
                height: 16px;
                margin-left: 2px;
                border-radius: 50%;
                background-color: var(--color-primary);
                transition: all 0.3s ease;
            }
        }
    }
}

.header {
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    
    [class*="themeselector"] {
        .themetoggle {
            .toggle {
                background-color: var(--color-primary);
                .toggle__circle {
                    background-color: #ffffff;
                }
            }
        }
    }
    .ffe-header__link {
        cursor: pointer;
        position: relative;
    }
    .mobilemenubtn {
        span {
            opacity: 1;
            transition: all 0.3s ease;
        }
        &::before, &::after {
            transition: all 0.3s ease;
        }
    }
    .openmenu::before {
        transform: translateY(8px) rotate(45deg);
    }
    .openmenu::after {
        transform: translateY(-8px) rotate(-45deg);
    }
    .openmenu span {
        opacity: 0;
    } 
    .mobilenav {
        position: fixed;
        background-color: white;
        width: 100%;
        top: 56px;
        left: 0px;
        height: 100vh;
        padding: 0px 18px;
        max-height: calc(100vh - 56px);
        overflow-y: scroll;
        overscroll-behavior: contain;
        transition: top 0.3s ease;
        .mobilenavdealerswitcher {
            font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
            text-align: left;
            position: relative;
            font-size: 18px;
            font-weight: 600;
            color: var(--color-primary);
            cursor: pointer;
        }
        .mobilenavlist {
            margin: 20px 0px;
            width: calc(100vw - 60px);
            li {
                font-family: 'SpareBank1-regular';
                font-size: 24px;
                font-weight: 600;
                color: var(--color-primary);
                cursor: pointer;

                .activemenuelement {
                    display: none;
                }

                &.activepage {
                    .activemenuelement {
                        position: absolute;
                        left:0;
                        margin-top:-5px;
                        width: 4px;
                        height:30px;
                        background-color: var(--color-primary);
                        border-bottom-right-radius: 3px;
                        border-top-right-radius: 3px;
                        display:block !important;
                    }
                }

                &.mobilenavcontaineritem {

                    .mobilenavcontainercontent {
                        display: none;
                        &.mobileopen {
                            display: block;
                        }
                        [class*="ffe-header__sublist-item"]{
                            padding-left:31px;
                        }
                   }
                
                    span {
                        rotate: 90deg;
                        float: left;
                        margin-right: 6px;
                    }

                    span.mobilearrowopen {
                        rotate:-90deg;
                    }
                }
                &.application_list_item{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    p {
                        margin-bottom: 0px;
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    [class*="ffe-header__wrapper"] {
        position: fixed;
        width: 100%;
        box-shadow: 0px 0px 10px 0px #00000026;
        background-color: white;
        z-index: 100;
        height: 62px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 16px;
        max-width: 1280px;
    }
}

.sidemenu {
    width: 273px;
    position: fixed;
    background-color: var(--color-primary);

    flex-direction: column;
    justify-content: flex-start;

    display: none;
    overflow: hidden;
    transition: all 0.3s ease;
    overflow-y: scroll;
    height: 100%;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    
    .logo {
        transition: all 0.3s ease;
        svg, .defaultLogo {
            margin: 45px;
            margin-bottom: 20px;
            .logo__text {
                transition: all 0.3s ease;
                opacity: 1;
            }
        }
    }
    .menuitemscontainer {
        transition: all 0.3s ease;
        margin-top: 30px;

        &:last-child {
            margin-top: auto;
        }
        
        ul[class*="ffe-header__sublist"] {
            padding: 0px;
            li[class*="ffe-header__sublist-item"] {
                display: flex;
                align-items: center;
            }
        }
        ul[class*="ffe-header__list"] {
            padding-left: 28px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        
            hr {
                width: calc(100% + 27px);
                margin-left: -29px;
                border: 1px solid var(--color-primary-light);
                margin-bottom: 25px;
                margin-top: 36px;
            }
            .bottomArea {
                overflow: hidden;
                width: 100%;
                white-space: nowrap;
                padding-top: 20px;
                [class*="logout"] {
                    height: 22px;
                    [class*="dooropen"] {
                        display: none;
                    }
                    &:hover {
                        [class*="doorclosed"] {
                            display: none;
                        }
                        [class*="dooropen"] {
                            display: inline-block;
                        }
                    }
                }

                [class*="collapsemenu"] {
                    [class*="openmenu"] {
                        display: none;
                    }
                }
            }
            li[class*="ffe-header__list-item"] {
                cursor: pointer;
                color: white;
                font-family: 'SpareBank1-regular';
                font-size: 16px;
                font-weight: 400;

                &.navcontaineritem {
                    cursor: default;
                    >p {
                        margin-left: 38px;
                    }
                }
                
                .activemenuelement {
                    display: none;
                }

                &.activepage {
                    .activemenuelement {
                        position: absolute;
                        left:0;
                        margin-top:-5px;
                        width: 4px;
                        height:30px;
                        background-color: white;
                        border-bottom-right-radius: 3px;
                        border-top-right-radius: 3px;
                        display:block !important;
                    }
                }

                >div {
                    display: flex;
                    align-items: center;
                    >span[class*="expandicon"] {
                        transition: all 0.3s ease;
                        margin-right: 0px;
                        font-size: 18px;
                        rotate: -90deg;
                        position: absolute;
                        right: 28px;
                    }
                    >span:not([class*="expandicon"]) {
                        font-size: 20px;
                        float: left;
                        margin-right: 6px;
                    }
                    >p {
                        transition: all 0.3s ease;
                        opacity: 1;
                        margin: 10px 0px;
                        display: flex;
                        align-items: flex-end;
                    }
                }
                &[class*="open"] {
                    >div {
                        >span[class*="expandicon"] {
                            rotate: 90deg;
                        }
                    }
                }
                >span[class*="expandicon"] {
                    font-size: 18px;
                    rotate: -90deg;
                    margin-right: 20px;
                    float: left;
                }
                >span:not([class*="expandicon"]) {
                    font-size: 20px;
                    float: left;
                    margin-right: 6px;
                }
                >p {
                    transition: all 0.3s ease;
                    opacity: 1;
                    margin: 22px 0px;
                    display: flex;
                    align-items: flex-end;
                }
                .navcontainerheader {
                    cursor: pointer;
                }
                .navcontainercontent {
                    background-color: var(--color-primary-light);
                    width: calc(100% + 30px);
                    margin-left: -29px;
                    padding-left: 70px;
                    &.notclickable {
                        pointer-events: none;
                        cursor: default;
                    }
                    [class*="ffe-header__sublist-item"] {
                        cursor: pointer;
                    }
                }

                .popout {
                    position: absolute;
                    top: 0px;
                    left: -200px;
                    pointer-events: none;
                    background-color: red;
                }
                .textarea {
                    justify-content: space-between;
                    >div {
                        margin-right: 20px;
                        transition: all 0.3s ease;
                        &:hover {
                            margin-right: 15px;
                        }
                    }
                    p {
                        margin: 0px;
                    }
                }
            }
        }

        p:hover {
            transition: all 0.3s ease;
            padding-left: 10px !important;
        }
    }
    &.collapsed {
        width: 57px;
        .logo {
            margin-left: -179px;
            svg {
                .logo__text {
                    opacity: 0;
                }
            }
        }
        [class*="navdealerswitcher"]{
            padding-left:11px;
            margin-left:-1px;
            background: none;

            [class*="spinbutton"]{
                right:-40px;
                background:none;
                color:white;
                opacity: 0;
                
            }
            [class*="accounticon"]{
                color:white;
                background:none;
                
            }

            p {
                height: 24px;
            }
        }

        .menuitemscontainer {
            margin-left: -10px;
        }
        ul[class*="ffe-header__list"] {
            li[class*="ffe-header__list-item"] {
                >div {
                    span[class*="expandicon"] {
                        margin-right: -60px;
                    }
                    >p {
                        opacity: 0;
                    }
                }
                >p {
                    opacity: 0;
                }
                &:hover {
                    .popout {
                        left: 30px;
                        p {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        
        [class*="collapsemenu"] {
            [class*="openmenu"] {
                display: inline-block !important;
            }
            [class*="closemenu"] {
                display: none;
            }
        }
    }
}

.contactmodal {
    position: fixed;
    background-color: #0000008f;
    width: 100%;
    height: 100%;
    z-index: 10000;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s, opacity 0.1s linear;
    padding: 20px;
    .content {
        width: fit-content;
        max-width: 100%;
        margin: auto;
        padding: 40px;
        background-color: white;
        box-shadow: 2px 2px 20px #00000057;
        position: relative;
        max-height: calc(100vh - 40px);
        overflow-y: auto;
        overscroll-behavior: contain;
        //-ms-overflow-style: none;  /* IE and Edge */
        //scrollbar-width: none;  /* Firefox */
        //&::-webkit-scrollbar {
        //    display: none;  /* Chrome, Safari, Opera*/
        //}


        scrollbar-color: #888 #f1f1f1;
        scrollbar-width: thin;
                
        &::-webkit-scrollbar {
            width: 4px;
          }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
        background: #555;
        }


        .helppageheader {
            h1 {
                font-weight: 500;
                font-family: "SpareBank1-title-medium";
                font-size: 28px;
                margin-top: 0px;
                margin-bottom: 30px;
                color: var(--color-primary);
                text-align: center;
            }
            .closebtn {
                position: absolute;
                top: 0px;
                right: 0px;
                cursor: pointer;
                padding: 20px;
            }
        }
        .helppagecontent {
            p {
                font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
                font-feature-settings: "tnum";
                font-variant-numeric: tabular-nums;
                font-size: 1rem;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                color: var(--color-primary);
            }
            .contentsegment {
                .contactitem {
                    font-family: 'SpareBank1-regular', 'MuseoSans-500', arial, sans-serif;
                    background-color: #D8E9F2;
                    border-radius: 4px;
                    padding: 16px 40px;
                    margin: 20px 0px;
                    h3 {
                        font-weight: 900;
                        font-size: 14px;
                        line-height: 24px;
                        color: var(--color-primary);
                        margin: 0px;
                        margin-bottom: 6px;
                    }
                    p {
                        color: black;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 28px;
                        margin: 0px;
                        display: flex;
                        span {
                            color: var(--color-primary);
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 765px) {
    .sidemenu {
        display: flex;
    }
    .header {
        display: none;
    }
}

[class*="ffe-header__border"] {
    border-bottom: none !important;
}

[class="ffe-header__logo"] {
    //margin-left: 40px;
    display: flex;
    justify-content: center;
    justify-content: space-evenly;
    align-items: center;
    max-width: 74vw;
    padding-left: 48px;
    svg {
        &:nth-child(2) {
            margin-left: 20px;
        }
    }
}

[class*="ffe-header__icon-button--site-nav"] {
    margin-top: 7px !important;
    &:focus {
        box-shadow: none !important;
    }
}