.giosg {
    
}

:global([class="giosg-chat-button"]) {
    @media print {
        display: none !important;
    }
    svg {
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.3)) !important;
        [class="giosg-speech-bubble"] {
            fill: #fff !important;
            transition: fill 0.2s ease-in-out;
        }
        [class="giosg-speech-bubble-icon"] {
            fill: #002776 !important;
            transition: fill 0.2s ease-in-out;
        }
    }
    &:hover {
        svg {
            [class="giosg-speech-bubble"] {
                fill: #002776 !important;
            }
            [class="giosg-speech-bubble-icon"] {
                fill: #fff !important;
            }
        }
    }
}