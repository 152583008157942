@import "../../../Variables.module.scss";

.insuranceoverview {
    .grid {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 0px 0px 0px 10px;
        @media (min-width: 1024px) {
            padding: 0px;
        }
        @media (max-width: 765px) {
            padding: 0px;
        }
        .tabgroup {
            align-items: center;
            display: flex;
            flex-direction: column;
        }

        .blur {
            filter: blur(5px);
        }
        .rightColumn{
            position: relative;
            .blurtext {
                position: absolute;
                top: 30px;
                left: 30px;
                bottom: 0;
                right: 30px;
                word-break: break-word;
                p {
                    margin-left: auto;
                    margin-right: auto;
                    background-color: white;
                    padding: 30px 40px;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    font-family: "SpareBank1-regular";
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
                }
            }
        }
        
    }
    .loanitemslist {
        padding: 0px 10px;

        .span {
            margin-left:5px;
        }

        .showmore {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            color: var(--color-primary);
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .loanactions {
        width: 100%;
        overflow: visible;
        height: 42px;
        .editloanbutton {
            float: right;
        }
    }
    .loaninfoarea {
        padding: 10px 10px;
        h2 {
            font-family: 'SpareBank1-regular';
            font-weight: 700;
            font-size: 18px;
            color: var(--color-primary);
            margin-bottom: 10px;
        }
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 400;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            span:nth-child(1) {
                float: left;
            }
            span:nth-child(2) {
                float: right;
                font-weight: 600;
                color: var(--color-primary);
            }
        }
    }
    .loancaseinfoarea {
        padding: 10px 10px;
        >p {
            margin: 0px;
            margin-bottom: 6px;
            font-family: 'SpareBank1-regular';
            font-size: 16px;
            font-weight: 400;
        }
        .loanstatus, .loantitle {
            color: var(--color-primary);
            font-weight: 600;
        }
        .loanpills {
            margin-top: 20px;
        }
    }
    .loanscoringcontent {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }
    .loandetails {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            >span:nth-child(1) {
                float: left;
                color: var(--color-primary);
                display: flex;
                align-items: center;
                [class*="material-symbols-outlined"] {
                    margin-left: 5px;
                    cursor: default;
                    font-size: 18px;
                }
            }
            >span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }
    .vehicle {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }
    .insurance {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }
    .personalinfo {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
        .fetchpersonbtn {
            text-align: center;
            text-decoration: underline;
            color: var(--color-primary);
            cursor: pointer;
        }
    }

}