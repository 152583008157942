.notifications {
    .sectiontitle {
        color: #565656b3;
        font-family: "SpareBank1-regular";
        font-weight: 400;
        font-size: 14px;
    }
    .new {
        ul {
            list-style: none;
            padding: 0;
            li {
                margin-bottom: 16px;
                .notification {
                    display: flex;
                    flex-direction: row;
                    .icon {
                        cursor: pointer;
                        padding-top: 1px;
                        span {
                            font-size: 32px;
                            color: var(--color-primary);
                        }
                    }
                    .content {
                        width: stretch;
                        width: -webkit-fill-available;
                        h3 {
                            cursor: pointer;
                            margin: 0px;
                            color: var(--color-primary);
                            font-family: "SpareBank1-regular";
                            font-weight: 600;
                            font-size: 14px;
                            padding: 10px;
                            transition: margin 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
                            &:hover {
                                margin-left: 6px;
                                color: #0035a0;
                            }
                        }
                    }
                    .time {
                        padding-top: 10px;
                        p {
                            margin: 0px;
                            color: var(--color-primary);
                            font-family: "SpareBank1-regular";
                            font-weight: 400;
                            font-size: 14px;
                        }
                    }
                }
                .notificationtext {
                    p {
                        margin: 0px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        color: var(--color-primary);
                        font-family: "SpareBank1-regular";
                        font-weight: 400;
                        font-size: 14px;
                    }
                    a {
    
                    }
                    button {
                        height: 30px;
                        padding: 10px 20px;
                        transition: background-color 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
                    }
                }
            }
        }
    }
    .archive {
        margin-top: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        >h2 {

        }
        >p {
            text-decoration: underline;
            cursor: pointer;
            margin: 0px;
            color: var(--color-primary);
            font-family: "SpareBank1-regular";
            font-weight: 400;
            font-size: 14px;
            width: fit-content;
            float: right;
        }
    }
}