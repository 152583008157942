@import "../../../Variables.module.scss";

.loanoverview {
    max-width: 1200px;
    .grid {
        width: calc(100% + 16px);
        margin-left: -8px;
        padding: 0px 0px 0px 10px;
        @media (min-width: 1024px) {
            padding: 0px;
        }
        @media (max-width: 765px) {
            padding: 3px;
        }
        .tabgroup {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }
    .loanitemslist {
        padding: 0px 10px;

        .span {
            margin-left:5px;
        }

        .showmore {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            color: var(--color-primary);
            cursor: pointer;
            text-decoration: underline;
        }

        .nocases {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            color: var(--color-primary);
        }

        .activeloanitem {
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
            border-left: 4px solid var(--color-primary);
            margin-left: -30px;
            padding-left: 26px;
        }

        [class*="ffe-loading-spinner"] {
            width: 100%;
            margin: 30px 0px;
            text-align: center;
        }

        .errorloanitem {
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
            border-left: 4px solid red;
            margin-left: -30px;
            padding-left: 26px;
            h2 {
                color: red;
            }
        }
    }
    .loanactions {
        width: 100%;
        overflow: visible;
        height: 42px;
        .editloanbutton {
            float: right;
        }
    }
    .loaninfoarea {
        padding: 10px 10px;
        h2 {
            font-family: 'SpareBank1-regular';
            font-weight: 700;
            font-size: 18px;
            color: var(--color-primary);
            margin-bottom: 10px;
        }
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 400;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            span:nth-child(1) {
                float: left;
            }
            span:nth-child(2) {
                float: right;
                font-weight: 600;
                color: var(--color-primary);
            }
        }
        .locked {
            float: right;
            margin-top: -23px;
            color: #e22d50;
        }
    }
    .esigningbutton {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
    }
    .warnings {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
            color: #e12d2f;
        }
        p {
            margin: 0px;
            margin-top: 2px;
            margin-left: 10px;
            font-family: 'SpareBank1-regular';
            font-size: 16px;
            font-weight: 400;
            color: #e12d2f;
        }
    }
    .detailedInformation {
        align-items: center;
        margin-bottom: 10px;
        margin-top: 20px;
        span {
            font-weight: 600;
        }
        p {
            font-weight: 400;
        }
        p, span {
            margin: 0px;
            margin-top: 2px;
            font-family: 'SpareBank1-regular';
            font-size: 16px;
            color: var(--color-primary);
        }
    }
    .loancaseinfoarea {
        padding: 10px 10px;
        >p {
            margin: 0px;
            margin-bottom: 6px;
            font-family: 'SpareBank1-regular';
            font-size: 16px;
            font-weight: 400;
        }
        .loanstatus, .loantitle {
            color: var(--color-primary);
            font-weight: 600;
        }
        .loanpills {
            margin-top: 20px;
            overflow: auto;
        }
        .warnings {
            margin-top: 16px;
            margin-bottom: 0px;
        }
    }
    .loanscoringcontent {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }
    .loandetails {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
        button.printbutton {
            margin: 20px auto;
            display: block;
        }
    }
    .vehicle {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }
    .insurance {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }
    .personalinfo {
        p {
            font-family: 'SpareBank1-regular';
            font-weight: 600;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 4px;
            width: 100%;
            overflow: hidden;
            padding: 16px 0px;
            span:nth-child(1) {
                float: left;
                color: var(--color-primary);
            }
            span:nth-child(2) {
                float: right;
                text-align: right;
                font-weight: 400;
            }
        }
    }

    .loadingspinner {
        display: none;
        padding: 20px 0px;
        justify-content: center;
    }
    .loadingsingle {
        .loadingspinner {
            display: flex;
        }
    }

}