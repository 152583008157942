body:has(.statistics){
    overflow-y: scroll;
}

.statistics {
    position: relative;
    .grid {
        .blurred {
            filter: blur(5px);
        }
        
        .gridrow {
            max-width: 100%;
            .gridcol {
                padding: 0px 12px;
                h4 {
                    font-family: 'SpareBank1-regular';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    text-align: left;
                }
                [class*="ContentCard_content"] {
                    margin-top: 6px;
                }
            }
        }
    }
    .blurtext {
        position: absolute;
        width: 100%;
        top: 200px;
        h3 {
            background-color: white;
            padding: 30px 40px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            font-family: "SpareBank1-regular";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 24px;
            text-align: center;
            width: fit-content;
            margin: auto;
        }
    }
    @media screen and (max-width: 860px) {
        .blurtext {
            margin: 20px;
            width: calc(100% - 40px);
        }
    }
}