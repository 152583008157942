.attachmentscontainer {


    .attachmentheadline {
        color:var(--color-primary);
        font-size: 18px;
        font-weight: 700;
        font-family: "SpareBank1-regular";
        height:24px;
        width: 100%;
        text-align: center;
        position: relative;
        .altoptbutton {
            position: absolute;
            right: 0px;
            top: -7px;
        }
    }
    
    .attachments {
        display: flex;
        border: 2px solid var(--color-primary);
        border-radius: 10px;
        // height:44px;
        align-items: center;
        padding:6px;
        cursor: pointer;
        color:var(--color-primary);
        margin-bottom:  12px;
        font-family: 'SpareBank1-regular';
        font-weight: 400;
        font-size: 16px;

        p {
            margin: 0px;
            &:first-child {
                margin-bottom: 4px;
            }
            &:last-child {
                font-size: 10px;
            }
        }
        &:hover {
            background-color: var(--color-primary);
            color: white;
            span {
                color:white;
            }
        }
        
        span {
            color:var(--color-primary);
            rotate: -90deg;
            margin-right: 5px;
        }


    }
}